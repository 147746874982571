import { Card, List, SimpleGrid, Stack, Text, Title, rem } from "@mantine/core";
import { useTranslation } from "next-i18next";
import IconCard from "@/public/icons/icon-cart-check.svg";
import IconQrCode from "@/public/icons/icon-qr-code.svg";
import IconTickets from "@/public/icons/icon-tickets.svg";

export default function HowToOrder(): JSX.Element {
  const { t } = useTranslation();

  const GuideCard = ({
    title,
    description,
    icon,
  }: {
    title: string;
    description: string;
    icon: React.ReactNode;
  }) => {
    return (
      <Card
        withBorder
        className="border-red-1 md:max-md:w-1/3 w-full rounded-md h-[272px]"
      >
        <Stack className="h-full justify-center items-center gap-1.5">
          {icon}
          <Title
            order={3}
            className="font-bold mt-5 mb-2 !text-dark-9 text-lg leading-6"
          >
            {title}
          </Title>
          <Text className="text-base !text-dark-8 text-center">
            {description}
          </Text>
        </Stack>
      </Card>
    );
  };

  return (
    <>
      <SimpleGrid cols={{ base: 1, md: 3 }} className="gap-6">
        <GuideCard
          icon={<IconCard />}
          title={`01. ${t("top:sections.boarding.steps.0.title")}`}
          description={t("top:sections.boarding.steps.0.description")}
        />
        <GuideCard
          icon={<IconQrCode />}
          title={`02. ${t("top:sections.boarding.steps.1.title")}`}
          description={t("top:sections.boarding.steps.1.description")}
        />
        <GuideCard
          icon={<IconTickets />}
          title={`03. ${t("top:sections.boarding.steps.2.title")}`}
          description={t("top:sections.boarding.steps.2.description")}
        />
      </SimpleGrid>
      <List
        classNames={{
          root: "text-red-9",
        }}
        my={rem(15)}
        w="95%"
      >
        <List.Item>
          <Text size="sm" c="red.9">
            {t("top:sections.boarding.remarks.0")}
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm" c="red.9">
            {t("top:sections.boarding.remarks.1")}
          </Text>
        </List.Item>
        <List.Item>
          <Text size="sm" c="red.9">
            {t("top:sections.boarding.remarks.2")}
          </Text>
        </List.Item>
      </List>
    </>
  );
}
